<template>
  <div class="h-full">
    <Slideshow class="h-[33vh] md:h-[65vh] mb-2" :images="[banner]" />

    <div class="max-w-4xl mx-auto text-center">
      <p class="text-lg md:text-xl p-4">
        Besoin d'un moment de détente, de lâcher prise, le massage Bien-Être peut être à la fois relaxant, énergisant et tonique.
      </p>
    </div>
    <StraightLine class="mt-2 md:mt-4" />

    <Prices />

    <StraightLine class="mt-2 mb-5" />
    <div class="max-w-4xl mx-auto">
      <p class="text-lg p-4">
        Différentes techniques existent pour personnaliser votre prise en charge et faire de cette expérience un moment unique rien que pour vous.
      </p>
      <p class="text-lg p-4">Dans un environnement convivial, je vous invite dans ma maison pour découvrir les bienfaits du massage.</p>
    </div>
    <StraightLine class="mt-5" />
  </div>
</template>

<script>
import Prices from '@/components/prices/Index';
import Slideshow from '@/components/Slideshow';
import StraightLine from '@/components/StraightLine';

export default {
  name: 'Home',
  components: { Prices, Slideshow, StraightLine },
  data() {
    return {
      banner: require('../assets/img/banner.jpg')
    };
  }
};
</script>
