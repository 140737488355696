<template>
  <button
    @click="$router.push('massages')"
    class="inline-flex justify-center w-full px-4 py-3 mt-8 font-sans text-sm leading-none text-center text-accent no-underline bg-transparent border border-secondary rounded-md cursor-pointer hover:bg-accent hover:border-accent hover:text-white focus-within:bg-accent focus-within:border-accent focus-within:text-white sm:text-base md:text-lg"
  >
    En savoir plus
  </button>
</template>

<script>
export default {};
</script>

<style></style>
