<template>
  <div class="flex flex-col h-full items-center lg:justify-center">
    <div class="mb-2">
      <template v-for="(m, index) in momMassages">
        <h3 :key="`${index}-${m.name}`" class="m-0 text-2xl font-semibold leading-tight tracking-tight text-black border-0 border-gray-200">
          <p class="box-border m-0 border-solid" style="border-image: initial">{{ m.name }}</p>
        </h3>
        <div :key="`${index}-${m.price}`" class="flex justify-center mt-6 leading-7 text-gray-900 border-0 border-gray-200">
          <p class="box-border m-0 text-4xl font-semibold leading-none border-solid">{{ m.price }}€</p>
        </div>
      </template>

      <template v-for="(m, index) in babyMassages">
        <div class="flex items-center" :key="`${index}-${m.price}`">
          <h3 class="m-0 mt-7 mr-2 text-xl font-semibold leading-tight tracking-tight text-black border-0 border-gray-200 lg:text-lg w-2/3">
            <p class="box-border m-0 border-solid" style="border-image: initial">{{ m.name }}</p>
          </h3>
          <div class="flex mt-6 leading-7 text-gray-900 border-0 border-gray-200">
            <p class="box-border m-0 text-2xl font-semibold leading-none border-solid">{{ m.price }}€</p>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    momMassages: {
      type: Array,
      required: true
    },
    babyMassages: {
      type: Array,
      required: true
    }
  }
};
</script>

<style></style>
