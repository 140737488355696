<template>
  <div class="">
    <div class="mb-10 relative flex flex-col items-center">
      <h3 class="m-0 md:text-3xl text-2xl font-semibold leading-tight tracking-tight text-black border-0 border-gray-200">
        <p class="box-border m-0 border-solid" style="border-image: initial">Les massages du monde</p>
      </h3>
      <ul class="flex-1 ml-3 md:ml-6 p-0 mt-8 leading-7 text-gray-900 border-0 border-gray-200">
        <li v-for="(m, index) in principalMassages" :key="`${index}-${m.name}`" class="inline-flex items-center block w-1/2 mb-2 font-semibold text-left">
          <CircleIcon />
          {{ m.name }}
        </li>
      </ul>
      <div class="flex items-end mt-6 leading-7 text-gray-900 border-0 border-gray-200">
        <p class="box-border m-0 text-6xl font-semibold leading-none">65€</p>
      </div>
    </div>

    <div class="relative flex flex-col items-center pt-8 border-t border-t-secondary">
      <MomMassagesPrices :mom-massages="momMassages" :baby-massages="babyMassages" />
      <CoupleMassages :massages="coupleMassages" />
    </div>
  </div>
</template>

<script>
import CircleIcon from '../CircleIcon.vue';
import MomMassagesPrices from './MomMassagesPrices';
import CoupleMassages from './CoupleMassages';

export default {
  components: { CircleIcon, MomMassagesPrices, CoupleMassages },
  props: {
    principalMassages: {
      type: Array,
      required: true
    },
    babyMassages: {
      type: Array,
      required: true
    },
    momMassages: {
      type: Array,
      required: true
    },
    coupleMassages: {
      type: Array,
      required: true
    }
  }
};
</script>

<style></style>
