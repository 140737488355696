<template>
  <VueFlux v-bind="properties" ref="slideshow">
    <template v-if="properties.images.length > 1" v-slot:controls>
      <button class="hover:shadow-lg bg-accent rounded-full pt-2 pb-8 px-2 text-white cursor-pointer absolute inset-y-1/2 left-3">
        <ChevronLeftIcon @click.stop="$refs.slideshow.show('prev')" />
      </button>
      <button class="hover:shadow-lg bg-accent rounded-full pt-2 pb-8 px-2 text-white cursor-pointer absolute inset-y-1/2 right-3">
        <ChevronRightIcon @click.stop="$refs.slideshow.show('next')" />
      </button>
    </template>
    <template v-if="properties.captions.length" v-slot:caption>
      <flux-caption />
    </template>
  </VueFlux>
</template>

<script>
import { ChevronLeftIcon, ChevronRightIcon } from '@vue-hero-icons/outline';

export default {
  components: { ChevronLeftIcon, ChevronRightIcon },
  props: {
    images: {
      type: Array,
      required: true
    },
    captions: {
      type: Array,
      default: () => []
    },
    autoplay: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      properties: {
        images: [...this.images],
        captions: [...this.captions],
        options: {
          allowFullscreen: true, //	Allows the slider to be displayed in full screen
          allowToSkipTransition: true, //	If enabled you will be able to skip the running transition, otherwise you need to wait to interact again
          aspectRatio: '16:9', //	Aspect ratio to set the slider by width:height ratio
          autohideTime: 2500, //	The time in ms that the controls and index buttons remain visible. If set to 0 they will never disappear
          autoplay: this.autoplay, //	Autoplay images when preload finished
          bindKeys: false, //	Binds the arrow keys of keyboard to show next or previous
          delay: 5000, //	The time in ms that an image will be displayed before changing to next
          enableGestures: true, //	Define if in touchable screens should use gestures instead of showing control and index buttons
          infinite: true, // The slider will start over when reaches the last image, otherwise will stop
          lazyLoad: true, //	Enables or disables lazy loading of images. If disabled, transitions will not begin until all images have been loaded
          lazyLoadAfter: 3 //	Indicates how many images have to be loaded before starting to load the rest in background
          // path: null, //		The images common path, which will be prepended to images URL
        },
        transitions: [
          //'blinds2d' //	TransitionBlinds2d	Divides the image in vertical bars that blinds and fades out
          // 'blinds3d' //	TransitionBlinds3d	Divides the image in vertical bars that blinds 180 deg to form the next image
          // 'blocks1' //	TransitionBlocks1	The image is splited in blocks that shrink and fade out randomly
          // 'blocks2' //	TransitionBlocks2	The image is splited in blocks that shrink and fade out in wave from a corner to the opposite
          // 'book' //	TransitionBook	Makes the effect of turning a page to display next image
          // 'camera' //	TransitionCamera	From outside to inside the image is being cropped like in a camera lens
          // 'concentric', //	TransitionConcentric	A concentric effect is performed by rotating the image converted into circles
          // 'cube', //	TransitionCube	Turns the image to a side like if place in a cube
          // 'explode' //	TransitionExplode	The image is splited in blocks that explode revealing the next image
          'fade' //	TransitionFade	Fades from one image to next
          // 'fall' //	TransitionFall	The image falls in front displaying next image
          // 'kenburn' //	TransitionKenburn	Fades, zoom and moves current image to next
          //'round1' //	TransitionRound1	The image is splited in blocks that turn 180 deg horizontally to form next image
          // 'round2' //	TransitionRound2	The image is splited in blocks that round vertically until disappear
          // 'slide' //	TransitionSlide	Slides the image horizontally revealing the next
          // 'swipe' //	TransitionSwipe	Swipes the image to display next like uncovered with a curtain
          // 'warp' //	TransitionWarp	A concentric effect is performed by rotating the image converted into circles in alternate direction
          // 'waterfall' //	TransitionWaterfall	Divides the image in bars and drops them down in turns
          // 'wave' //	TransitionWave	Makes the image 3D and divides it in slices that turn vertically to display the next image
          // 'zip' //	TransitionZip	Divides the image in bars and slides them up and down alternately like a zip
        ]
      }
    };
  }
};
</script>

<style></style>
