<template>
  <svg
    class="w-5 h-5 pl-2 pt-2 text-accent sm:h-5 sm:w-5 md:h-6 md:w-6"
    fill="currentColor"
    stroke="none"
    viewBox="0 0 200 200"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="50" cy="50" r="37.5" />
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
