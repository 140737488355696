<template>
  <!-- Section 2 -->
  <section class="py-8 leading-7 text-gray-900 bg-white sm:py-4 md:py-8 lg:py-10">
    <div class="px-4 mx-auto border-solid sm:px-6 md:px-6 lg:px-8 max-w-7xl">
      <div class="flex flex-col items-center leading-7 text-center text-gray-900 border-0 border-gray-200">
        <Title class="text-black text-3xl">Un large choix de massages</Title>
        <p class="mt-2 text-xl text-gray-900 border-solid sm:text-2xl">Détente garantie</p>
      </div>
      <div
        class="flex flex-col items-center lg:flex-row lg:justify-center lg:items-stretch gap-4 mt-4 leading-7 text-gray-900 border-0 border-gray-200 sm:mt-6 sm:gap-6 md:mt-8 md:gap-0"
      >
        <div
          class="order-3 lg:order-1 max-w-lg lg:max-w-md min-h-[270px] z-10 shadow-md border border-solid rounded-lg w-full lg:w-auto relative p-4 my-0 sm:p-6 lg:my-8 md:p-8 lg:p-6"
        >
          <Massages :massages="singularMassages" title="Les singuliers" />
          <div class="absolute bottom-5 w-[90%] left-[5%]">
            <MassagesLink />
          </div>
        </div>
        <div
          class="order-1 lg:order-2 max-w-lg lg:max-w-md z-20 shadow-xl border-secondary border-4 bg-white border-solid rounded-lg w-full lg:w-auto relative p-4 my-0 sm:p-6 md:px-8 md:py-16 lg:pb-28"
        >
          <MassagesPrices
            class="mb-10 md:mb-4"
            :principal-massages="principalMassages"
            :mom-massages="momMassages"
            :baby-massages="babyMassages"
            :couple-massages="coupleMassages"
          />
          <div class="absolute bottom-2 w-[90%] left-[5%] lg:bottom-14 lg:w-[85%] lg:left-[7.5%]">
            <MassagesLink />
          </div>
        </div>
        <div
          class="order-2 lg:order-3 max-w-lg lg:max-w-md min-h-[320px] z-10 shadow-md border border-solid rounded-lg w-full lg:w-auto relative p-4 my-0 sm:p-6 md:my-8 md:p-8 lg:p-6"
        >
          <Massages :massages="particularMassages" title="Les particuliers" />
          <div class="absolute bottom-5 w-[90%] left-[5%]">
            <MassagesLink />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import config from '@/assets/config.json';
import Title from '../Title';
import MassagesPrices from './MassagesPrices';
import Massages from './Massages';
import MassagesLink from './MassagesLink';

export default {
  components: { Title, MassagesPrices, Massages, MassagesLink },
  computed: {
    massages() {
      return config.massages;
    },

    principalMassages() {
      return this.massages.filter((m) => m.category === 'massages-monde');
    },
    singularMassages() {
      return this.massages.filter((m) => m.category === 'massages-singular');
    },
    particularMassages() {
      return this.massages.filter((m) => m.category === 'massages-particular');
    },
    momMassages() {
      return this.massages.filter((m) => m.category === 'massages-mom');
    },
    babyMassages() {
      return this.massages.filter((m) => m.category === 'massages-baby');
    },
    coupleMassages() {
      return this.massages.filter((m) => m.category === 'massages-couple');
    }
  }
};
</script>

<style></style>
