<template>
  <h2 class="m-0 text-2xl font-semibold leading-tight tracking-tight border-solid md:text-4xl">
    <slot></slot>
  </h2>
</template>

<script>
export default {};
</script>

<style></style>
