<template>
  <RouterLink class="cursor-pointer rounded text-slate-700" :to="to" exact active-class="decoration-2 underline-offset-6">
    <slot></slot>
  </RouterLink>
</template>

<script>
export default {
  props: {
    to: {
      type: String,
      required: true
    }
  }
};
</script>

<style>
.underline-offset-6 {
  text-decoration-line: underline;
  text-underline-offset: 6px;
}
.decoration-2 {
  text-decoration-thickness: 2px;
}
</style>
