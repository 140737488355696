import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './assets/styles.css';

import { VueFlux, FluxCaption, FluxControls, FluxIndex, FluxPagination, FluxPreloader } from 'vue-flux';

Vue.component('VueFlux', VueFlux);
Vue.component('FluxCaption', FluxCaption);
Vue.component('FluxControls', FluxControls);
Vue.component('FluxIndex', FluxIndex);
Vue.component('FluxPagination', FluxPagination);
Vue.component('FluxPreloader', FluxPreloader);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app');
