<template>
  <header :class="['bg-white max-w-container max-h-[max(20vh,20vw)] md:bg-primary flex items-end shadow-md font-' + font]">
    <div class="bg-white flex justify-center h-[max(20vh,20vw)] md:h-[max(15vh,15vw)] lg:h-[15vh] w-[95%] md:w-[40%] lg:w-1/3 2xl:w-1/4 p-6 md:p-2 md:pl-0">
      <img class="h-full" alt="logo" src="../assets/logo.svg" />
    </div>

    <button
      ref="toggle"
      class="absolute z-[9999] top-4 right-2 md:hidden"
      :class="[linksVisible ? 'text-white' : 'text-primary']"
      @click="toggleMenu(!linksVisible)"
      aria-controls="nav-links"
      :aria-expanded="linksVisible"
    >
      <span class="sr-only">Menu</span>
      <transition enter-active-class="animate-duration-[1.2s] animate-rotateIn" leave-active-class="animate-faster animate-rotateOut">
        <MenuIcon v-if="!linksVisible" class="inline-block absolute right-0" key="menu" size="42" />
        <XIcon v-else class="inline-block absolute right-0" key="close" size="42" />
      </transition>
    </button>

    <transition enter-active-class="animate-fast animate-slideInRight md:animate-none" leave-active-class="animate-fast animate-slideOutRight md:animate-none">
      <nav
        id="nav-bar"
        v-show="linksVisible"
        class="h-auto lg:pr-[10%] md:w-[60%] lg:w-2/3 2xl:w-3/4 md:ml-5 p-5 pt-20 pl-10 md:p-5 md:pl-0 fixed inset-0 left-[50%] z-[9998] md:relative md:inset-auto"
      >
        <ul
          @click="linkClicked"
          id="nav-links"
          class="flex flex-col md:flex-row gap-4 lg:gap-10 md:items-center md:justify-end text-lg md:text-md lg:text-lg text-white"
        >
          <li class="">
            <Navlink to="/">Accueil</Navlink>
          </li>
          <li class="">
            <Navlink to="/massages">Massages</Navlink>
          </li>
          <li class="">
            <Navlink to="/about">Qui suis-je ?</Navlink>
          </li>
          <li class="">
            <Navlink to="/access">Plan d'accès</Navlink>
          </li>
        </ul>
      </nav>
    </transition>
  </header>
</template>

<script>
import { MailIcon, MenuIcon, XIcon } from '@vue-hero-icons/outline';
import Navlink from './Navlink';
import getBreakpoint from '@/plugins/breakpoints.js';

export default {
  props: {
    value: {
      type: Boolean,
      required: false
    },
    font: {
      type: String,
      required: false,
      default: 'sans',
      validator: (val) => ['sans', 'mono', 'serif'].includes(val)
    }
  },
  components: { Navlink, MailIcon, MenuIcon, XIcon },
  data() {
    return {
      linksVisible: true,
      timeoutResize: null
    };
  },
  created() {
    const breakpoint = getBreakpoint();
    this.linksVisible = breakpoint !== 'xs' && breakpoint !== 'sm';
    window.addEventListener('resize', this.renderLinksIfNotMobile);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.renderLinksIfNotMobile);
  },
  methods: {
    toggleMenu(val) {
      this.linksVisible = val;
      this.$emit('input', val);
    },
    isMobile() {
      let isMobile = false;
      const toggle = this.$refs.toggle;

      if (toggle) {
        const display = window.getComputedStyle(toggle, null).display;
        isMobile = display !== 'none';
      }
      return isMobile;
    },
    renderLinksIfNotMobile() {
      if (this.timeoutResize) window.clearTimeout(this.timeoutResize);
      this.timeoutResize = window.setTimeout(() => {
        const visible = !this.isMobile();
        this.toggleMenu(visible);
      }, 300);
    },
    linkClicked() {
      if (this.isMobile()) {
        this.toggleMenu(false);
      }
    }
  }
};
</script>

<style lang="postcss" scoped>
#nav-bar {
  @apply bg-primary;
}
@supports (backdrop-filter: blur(12px)) {
  #nav-bar {
    @apply bg-primary/70 backdrop-blur-md;
  }
}
</style>
