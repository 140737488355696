<template>
  <div class="h-full px-4 py-2 text-center text-primary">
    <h4 class="ml-5 mb-4 mt-5 text-md md:text-lg text-white inline-block">
      Elisabeth est à votre écoute pour répondre à vos attentes du moment et vous conseiller
    </h4>
    <p class="mb-4 text-xl md:text-2xl font-bold"><a href="tel:+33766394220">Réception uniquement sur RDV au 07 66 39 42 20</a></p>
    <p class="mb-4 text-md md:text-lg">Tous paiements acceptés sauf CB</p>
  </div>
</template>
<script>
export default {};
</script>
