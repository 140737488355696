<template>
  <div id="app" class="h-full font-sans">
    <Navbar />
    <router-view />
    <div class="bg-accent">
      <Contact />
    </div>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar';
import Contact from '@/components/Contact';

export default {
  components: { Navbar, Contact },
  provide: {
    theme: { flat: true }
  }
};
</script>

<style lang="scss">
#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
